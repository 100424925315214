<template>
  <div>
    <div>
      <el-form :inline="true" :model="form" ref="departmentInfo" size="small">
        <el-form-item style="margin-left: 20px" label="公司名称">
          <el-input
            v-model.trim="form.name"
            placeholder="请输入公司名称"
            style="width: 200px"
            maxlength="64"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="姓名/手机号码">
          <el-input
            v-model.trim="form.namber"
            placeholder="请输入姓名或手机号码"
            style="width: 200px"
            maxlength="64"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item style="margin-left: 20px">
          <el-button type="primary" @click="getTableData">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table
        :data="tableData"
        style="width: 100%"
        stripe
        :height="tableHeight"
        border
      >
        <el-table-column type="index" label="序号" width="80">
        </el-table-column>
        <el-table-column prop="name" label="公司名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="number"
          label="姓名"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="number"
          label="手机号码"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="number"
          label="委托资料"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="number"
          label="申请时间"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-link type="primary" :underline="false" @click="show(scope.row)"
              >审核</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-box mt20r">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="changeCurrent"
        :current-page="form.current"
        :page-sizes="[10, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="审核"
      :visible.sync="centerDialogVisible"
      width="500px"
      center
    >
      <div class="dialogDiv">
          <div class="smallBox">
              <div class="label">姓&nbsp&nbsp&nbsp&nbsp名</div>
              <div>{{content.name}}</div>
          </div>
          <div class="smallBox">
              <div class="label">手机号码</div>
              <div>{{content.name}}</div>
          </div>
          <div class="smallBox">
              <div class="label">委托资料</div>
              <div>
                  <img src="" alt="" style="width:200px">
              </div>
          </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">拒 绝</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >同 意</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
        content:{},
      total: 0,
      centerDialogVisible: false,
      tableData: [{name: "123"}],
      form: {
        size: 10,
        current: 1,
      },
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    show(data) {
        this.centerDialogVisible = true
    },
    //获取数据
    getTableData() {},
    // 分页
    changeCurrent(val) {
      this.form.current = val;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.form.size = val;
      this.form.current = 1;
      this.getTableData();
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  text-align: right;
}
.dialogDiv{
    width: 350px;
    margin: 0 auto;
    .smallBox{
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .label{
            margin-right: 40px;
        }
    }
}
</style>
